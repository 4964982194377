.fission-yield-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.yield-icon {
    background: white;
    font-style: normal;
    padding: 2px 4px;
    -webkit-text-stroke-width: 1.25px;
    -webkit-text-stroke-color: black;
}

.triangle::before {
    content: "\25E2";
}

.square::before {
    content: "\25A0";
    font-size: 24px;
}

