/* Legend Container Styles */
.legend-container {
    justify-content: center;
    align-items: center;
}
.legend-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    position: relative
}
.legendModal {
    border: 5px solid #3b3b3b;
    background: #fff;
    width: 60vw;
    height: 400px;
    margin: 60px auto;
}
@media only screen and (max-width: 992px) {
    .legendModal {
        width: 95vw;
    }
}
@media only screen
and (min-width: 600px)
and (max-width: 1000px)
and (orientation: landscape) {
    .legendModal {
        height: 85vh;
        margin: 40px auto;
    }
}

@media only screen
and (min-width: 820px)
and (max-width: 830px)
and (orientation: landscape) {
    .legend-inner-container {
        padding-top: 0;
        margin: 8vh;
    }
}

/* Styling for data legends on portrait mode, because they do NOT rotate on mobile browsers. */
@media screen and (min-color-index:0) and (-webkit-min-device-pixel-ratio:0) {
    @media only screen
    and (min-width: 320px)
    and (max-width: 480px) {
        .legend-inner-container .lower-canvas, .legend-inner-container .upper-canvas, .legend-inner-container .canvas-container  {
            width: 360px !important;
            height: 162px !important;
        }
        .legendModal {
            height: 60vh !important;
        }
    }
}

@media only screen and (min-width: 830px) {
    .legend-inner-container .lower-canvas, .legend-inner-container .upper-canvas, .legend-inner-container .canvas-container  {
        height: 360.36px !important;
        width: 800px !important;
    }
}

@media only screen and (min-width: 830px) and (max-width: 1025px) {
    .legendModal {
        width: 80vw;
    }
}

@media only screen 
and (min-width: 730px) 
and (max-width: 811px) {
    .legend-inner-container {
        padding-top: 0;
        margin: 6vh;
    }
}
@media screen 
  and (min-height: 823px) 
  and (-webkit-device-pixel-ratio: 3.5) 
  and (orientation: landscape) {
 
}

.legend-inner-container .lower-canvas, .legend-inner-container .upper-canvas {
    align-content: center
}