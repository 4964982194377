
.fission-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #515151;
}

.fission-grid-container {
    display: grid;
    grid-template-columns: 10em 20em;
    grid-row: auto;
    margin: 20px auto;
}

@media only screen and (max-width: 767px) {
    .fission-grid-container {
        display: grid;
        grid-template-columns: 4em 14em;
    }
}


.orange-tri::before {
    color: #ff8030;
}

.yellow-tri::before {
    color: #ffe74c;
}

.green-tri::before {
    color: #76e1c7;
}

.blue-tri::before {
    color: #9edaf2;
}

.black-tri::before {
    color: black;
}

.white-tri::before {
    color: white;
}

.black-square::before {
    color: black;
}
