/* Welcome Panels */

.NavBar {
    background-image: url(../../static/panels/1_NavBarPanel_min.png);
}

.Zoom {
    background-image: url(../../static/panels/2_ZoomPanel_min.png);
}

.Navigation {
    background-image: url(../../static/panels/3_NavigationPanel_min.png);
}

.Legend {
    background-image: url(../../static/panels/4_LegendPanel_min.png);
}

.Export {
    background-image: url(../../static/panels/5_ExportPanel_min.png);
}

.welcome-title {
    color: #515151;
    text-align: center;
}

.each-slide > div {
    margin: 0 50px 0 50px;
    padding: 0;
    display: grid;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 45vh;
}

.each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}

/* Welcome Panel Buttons */
.button-container {
    display: flex;
    justify-content: center;
    margin: 10px;
    padding: 10px;
}

.button-container > button {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    text-transform: none;
    border-style: none;
    background-color: transparent;
    outline:none;
}

.button-container > button:hover {
    cursor: pointer;
}

.continue-button {
    font-weight: bold;
    color: white;
    background-color: #515151 !important;
    border-radius: 20px;
    padding: 10px;
}

.continue-button:hover {
    background-color: #3bb99a !important;
    box-shadow: 0 4px 8px #cecece;
}

.skip {
    font-weight: bold;
    color: #515151;
    border-radius: 20px;
    padding: 10px;
}

.skip:hover {
    color: white;
    background-color: #51515188;
    box-shadow: 0 4px 8px #cecece;
}

.react-slideshow-container .nav {
    border-radius: 0 !important;
    padding-right: 15px;
    padding-left: 15px;
}

.react-slideshow-container .nav:hover {
    /* bg color looks like black but it's actually transparent */
    background: #ffffff00;
}

.react-slideshow-container .nav  span {
    border-radius: 15%;
    width: 20px !important;
    height: 20px !important;
    border-color: #515151;
}

.react-slideshow-container .nav  span:hover {
    border-color: #3bb99a;
    border-width: 6px !important;
}

.react-slideshow-container + div.indicators > div  {
    width: 15px !important;
    height: 15px !important;
}

.react-slideshow-container + div.indicators > div.active {
    background: #3bb99a
}

@media only screen
and (min-width: 415px)
and (max-width: 813px)
and (orientation: landscape) {
    /* Mobile styling for welcome panels */
    .each-slide > div {
        margin: 10px !important;
        height: 35vh !important;
    }
    .indicators {
        margin-top: 0 !important;
    }
    .button-container {
        padding: 3px !important;
        margin: 3px;
    }
    .welcome-title {
        margin-bottom: 1px !important;
    }
    .react-slideshow-container + div.indicators > div {
        width: 10px !important;
        height: 10px !important;
    }
}

@media only screen
and (min-width: 250px)
and (max-width: 600px)
and (orientation: portrait) {
    /* Mobile styling for welcome panels */
    .each-slide > div {
        height: 35vh !important;
    }

    .welcome-title {
        padding: 30px;
    }

    .each-slide > div {
        margin: 0 25px 0 25px !important;
        padding: 0 !important;
    }
    .react-slideshow-container + div.indicators {
        margin-top: 50px !important;
    }
}

