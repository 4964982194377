#root, .mainWrapper {
    width: 100vw;
    height: 100vh;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: fixed
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#chart {
    height: 90vh;
}

.filter_dropdown {
    margin: 5% 0;
    color: black;
}

/* Overwrites the dropdown libraries styling */
.css-bg1rzq-control, .css-1szy77t-control {
    min-width: 100px;
}

.css-kj6f9i-menu {
    color: #515151;
}

.css-11unzgr {
    max-height: 200px !important;
}

/* Menu field buttons styling */
.submit {
    background-color: #76e1c7;
    border: none;
    border-radius: 4px;
    padding: 12px 26px;
    color: white;
    font-size: large;
    box-shadow: 0 3px 7px #cecece;
}

.submit:hover {
    background-color: #3bb99a;
}

.submit:disabled {
    background-color: #c8f5ea;
}

button:active:enabled {
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
    -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
    box-shadow: inset 0px 0px 5px #c1c1c1;
}

.close {
    border: #e57373;
    background-color: white;
    size: 15px;
    color: #e57373;
    font-size: large !important;
    margin-top: 2%;
    border-radius: 6px;
    box-shadow: 0 3px 7px #999;
    float: right
}

.remove {
    background-color: #ed9f9f;
    border: none;
    size: 15px;
    color: white;
    font-size: small !important;
    margin-left: 3px;
    border-radius: 6px;
    box-shadow: 0 2px 5px #999;
}

.reset {
    border-color: #ff8030;
    border-radius: 6px;
    color: #f58f4f;
    font-size: large;
    box-shadow: 0 2px 6px #cecece;
    background-color: white;
    width: 40%;
    display: 'flex';
    margin: 0 10px 0 10px;
    padding: 6px 13px;
}

.reset:hover {
    background-color: #ff8030;
    color: white;
}

/* React modals styling */
.version_info {
    margin: 2.5em 1em 1em;
    color: #515151;
}

.FluorLogo {
    content:url(static/Fluor_logo-min.png);
    width: 20%;
}

.NNLLogo {
    content:url(static/NNL-Logo-min.png);
    width: 20%;
}

.BechtelLogo {
    content:url(static/Bechtel-logo-min.png);
    width: 15%;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

@media only screen and (max-width: 1024px) {
    .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo-item {
        flex: 1;
        width: 20%;
        padding: 1.5em;
    }
}

@media only screen and (max-width: 600px) {
    .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .logo-item {
        width: 60%;
        padding: 1.5em;
    }
}


.ReactModal__Overlay--after-open {
    opacity: 1;
    z-index: 1001;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}


@media only screen
and (min-width: 415px)
and (max-width: 813px)
and (orientation: landscape) {
    /* Mobile styling for nav bar */
    #chart {
        height: 85vh;
    }

    .navigationBar {
        height: 15vh;
    }

}

@media only screen and (max-width: 600px) {
    .accordion__button {
        width: 85% !important;
    }

    .submit {
        padding: 12px 14px !important;
    }

    .remove {
        float: right;
    }

    .version_info {
        margin-top: 15%
    }

    .continuous-input {
        max-width: 20vw;
    }

    .react-autosuggest__input {
        width: 27vw !important;
    }

    .atom-nucleus {
        bottom: 20px;
    }
}

@media only screen and (min-width: 601px) {
    /* Set hide/show on mobile classes */
    .leaflet-bottom .leaflet-control {
        bottom: 6% !important;
    }

    .leaflet-bottom {
        bottom: 6% !important;
    }
}

/* Landscape styling */
@media only screen
and (min-width: 375px)
and (max-width: 812px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) {
    .navigationBar {
        height: 15vh;
    }

    #chart {
        height: 85vh;
    }
}

@media only screen
and (min-width: 250px)
and (max-width: 600px)
and (orientation: portrait) {
    .bm-menu-wrap {
        width: 80% !important;
    }

    .colorSquare {
        width: 25px !important;
    }
}

/* Leaflet chart styling */
.leaflet-container {
    background-color: white !important;
    position: sticky !important;
}

.leaflet-control-attribution {
    display: none;
}

.leaflet-control-minimap {
    border: solid #515151 0.25px;
    box-shadow: 0 3px 7px #999;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 5px;
}

/* Minimap toggle styling */
.leaflet-control-minimap a {
    background-repeat: no-repeat;
    z-index: 99999;
    transition: all .6s
}

.leaflet-control-minimap a.minimized-bottomright {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    width: 30px !important;
    height: 30px !important;
}

.leaflet-control-minimap-toggle-display {
    background-image: url(static/toggle.png);
    background-size: cover;
    position: absolute;
    width: 20px !important;
    height: 20px !important;
}

.leaflet-control-minimap-toggle-display-bottomright {
    bottom: 1px;
    right: 1px
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}


.react-autosuggest__input {
    width: 17vw;
    height: 30px;
    padding: 0 5px;
    margin: 7px 0;
    font-size: 14px;
    border: 1px solid #aaa;
    border-radius: 12px;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    background-color: #515151;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 20;
    cursor: pointer;
    border-radius: 12px;
    margin-top: -2px;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    width: 10vw;
    font-weight: bold;
    border-radius: 12px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.error {
    border: 1px solid red;
}

.error, textarea {
    background: #f9e5e5;
}

.selectUnit {
    max-width: 65px;
    font-size: small;
}

@media only screen
and (min-width: 600px)
and (max-width: 1000px)
and (orientation: landscape) {
    .bm-menu-wrap {
        width: 70% !important;
        top: 54px !important;
    }

    .bm-overlay {
        top: 54px !important;
    }

    .sweet-alert {
        width: 50% !important;
        height: 80vh !important;
        margin-left: 20vw !important;
        margin-top: -45vh !important;
    }
    .navigationBar {
        height: 15vh !important;
    }
    .navigation-bar .menu-bar {
        height: 45px !important;
    }
}

div:focus, div:active {
    outline: none;
}

/* Loading Icon styling */
.animation-container {
    justify-content: 'center';
    align-items: 'center';
    position: relative;
    width: 45vh;
    height: 50vh;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.animation-ring {
    justify-content: 'center';
    align-items: 'center';
    border-radius: 50%;
    position: absolute;
    height: 40vh;
    width: 40vh;
}

.atom-nucleus {
    position: absolute;
    top: 0;
    left: 0;
    right: 10%;
    bottom: 18%;
    margin: auto;
    height: 6vh;
    width: 6vh;
}

.src {
    height: 6vh;
    width: 6vh;
}

.animation-ring:nth-child(1) {
    -webkit-transform: rotateX(75deg) rotateY(30deg);
    transform: rotateX(75deg) rotateY(30deg);
}

.animation-ring:nth-child(2) {
    -webkit-transform: rotateX(75deg) rotateY(-30deg);
    transform: rotateX(75deg) rotateY(-30deg);
}

.animation-ring:nth-child(3) {
    -webkit-transform: rotateX(180deg) rotateY(77deg);
    transform: rotateX(180deg) rotateY(77deg);
}

.animation-turn {
    width: 100%;
    height: 100%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
    border-radius: 50%;
}

.one {
    border: 7px solid #ff8030;
}

.two {
    border: 7px solid #9edaf2;
}

.three {
    border: 7px solid #76e1c7;
}

.animation-turn:after {
    content: "";
    position: absolute;
    top: -5px;
    left: 50%;
    width: 40px;
    height: 40px;
    box-shadow: 0 0 15px white;
    border-radius: 100%;
    background-color: white;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 100px;
    perspective: 100px;
    -webkit-animation: spinfast 0.1s linear infinite;
    animation: spinfast 0.1s linear infinite;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotateZ(360deg);
        transform: rotateZ(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotateZ(360deg);
        transform: rotateZ(360deg);
    }
}

.buttons {
    margin-top: 25px;
}

.bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    transition: color 0.2s;
    height: 10% !important
}

.bm-burger-bars {
    background: white;
    height: 15% !important;
    width: 90%;
}

.bm-cross-button {
    height: 24px;
    width: 24px;
    top: 10px !important;
}

.bm-cross {
    background: white;
}

.bm-menu {
    background: #515151;
    padding: 1.5em 1.5em 0;
    font-size: 1.15em;
    right: 10px;
    height: 95vh !important;
    box-shadow: 0 0 7px #373a47;
}

.bm-morph-shape {
    fill: #373a47;
}

.bm-item-list {
    height: 50% !important;
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    left: 0 !important;
    top: 60px;
}

.menu-item {
    color: #ff8030
}

@media only screen
and (min-width: 768px)
and (max-width: 1025px) {
    .bm-menu-wrap {
        width: 60% !important;
    }
}

/* Decay Chain styling */

.arrow-label {
    width: 35px !important;
    text-align: center;
    font-weight: bold;
    height: 25px !important;
    padding: 0 0 3px 0;
    text-shadow: 1px 1px 10px white;
    background: rgba(255, 255, 255, 1);
    border-radius: 6px;
    box-shadow: 1px 1px 10px gray;
    font-family: 'Times New Roman', Times, serif, sans-serif;
    color: #515151;
}

.delayed-decay-arrow-label {
    background: #515151;
    color: rgba(255, 255, 255, 1);
}

.menu-section-subtitle {
    font-weight: bold;
}

