.navigation-bar {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
    color: white;
    background-color: #515151;
}

.navigation-bar .menu-bar {
    position: relative;
    height: 50px;
    padding: 0;
    margin: 0;
}

.navigation-bar .menu-bar .icon {
    height: 50%;
    cursor: pointer;
}

.periodic {
    content:url(../../static/periodic-table-icon.png);
}

.chart {
    content:url(../../static/app-icon.png);
}

.navigation-bar .menu-bar li {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    margin-right: 6px;
}

.navigation-bar .menu-bar li:last-child {
    margin-right: 0;
}

.navigation-bar .menu-bar a {
    display: flex;
    height: 100%;
    padding: 0 5px;
    align-items: center;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
}

.navigation-bar .menu-bar ul {
    display: inline-block;
    height: 100%;
    padding: 0;
}

.navigation-bar .menu-bar .active {
    border-bottom: 2px solid white;
}

.navigation-bar .menu-bar .label {
    display: inline;
    margin-right: 15px;
    vertical-align: middle;
    cursor: default;
}

.navigation-bar .menu-bar .help {
    font-size: 2em;
    cursor: pointer;
}

.info-modal {
    position: fixed;
    width: 90%;
}

.modal {
    position: absolute;
    top: 7.5%;
    right: 1%;
    max-height: 80%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 5px;
    outline: none;
    padding: 20px;
    overflow-x: hidden
}

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-Color: rgba(83, 80, 92, 0.6);
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.modal-button {
    cursor: pointer;
    float: right;
    margin-top: 5px;
    color: #515151;
    width: 18px !important;
    height: 18px;
    border: 2px solid white;
}

@media only screen
and (min-width: 415px)
and (max-width: 813px)
and (orientation: landscape) {
    /* Mobile styling for nav bar */

    .navigation-bar {
        height: 12vh;
    }

    .navigation-bar .menu-bar {
        height: 100%;
    }

    .navigation-bar .menu-bar a {
        height: 90%;
    }

    .menu-btn {
        padding-bottom: 8px;
    }

}

@media only screen and (max-width: 600px) {
    .navigation-bar .menu-bar .label {
        margin-right: 10px;
    }

}

/* Navbar styling for iphone 5/SE */
@media only screen
and (min-width: 320px)
and (max-width:350px) {
    .navigation-bar .menu-bar {
        height: 38px !important;
    }
    .bm-menu-wrap, .bm-overlay {
        top: 48px !important;
    }
}

@media only screen
and (min-width: 375px)
and (max-width: 812px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) {
    .navigation-bar {
        height: 45px;
    }
}

@media only screen
and (min-width : 567px)
and (max-width : 569px)
and (orientation : landscape) {
    .bm-menu-wrap, .bm-overlay {
        width: 60% !important;
        top: 48px !important;
    }
}
