.outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #515151;
}

.grid-container {
    display: grid;
    grid-template-columns: 25em 25em;
    grid-row: auto;
    margin: 20px auto;
}

.inner-container {
    display: grid;
    font-family: "Times New Roman", serif;
    grid-template-columns: 5em 19em;
    width: 100%;
}

.inner-container:last-child {
    margin-right: 20px;
}

.unit-container {
    display: grid;
    font-family: "Times New Roman", serif;
    grid-template-columns: 5em 5em 15em;
    width: 100%
}

.unit-container:last-child {
    margin-right: 20px;
}

.headers {
    text-decoration: underline;
    font-weight: bold;
}

.box {
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content:flex-start;
}

.text {
    margin: 5px;
}


@media only screen and (max-width: 768px) {
    .grid-container {
        display: grid;
        grid-template-columns: 1fr;
        padding: 1em;
        grid-row-gap: 15px;
    }

    .inner-container {
        display: grid;
        grid-template-columns: 5em 15em;
        width: 100%;
    }
}
